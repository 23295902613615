@import '../../Constants.scss';

.home-card-section .p-card {
    height: 470px;
}

.home-card-section .p-card-header {
    height: 180px;

}

.home-card-section .p-card-content {
    height: 170px;
    overflow-y: hidden;
    padding: 0;
}


