@import '../../../../Constants.scss';

// .property-list-card {
//     width: 100%;
//     height: 100%;
//     background-color: #d9dde1;

//     min-height: 400px;
// }

.property-list {
    height: 100%;
}

.property-list .p-panel-content {
    padding: 0px;
    height: 100%;
}

.property-list .p-toggleable-content {
    height: calc(100% - 59px);
}

.property-list .p-datatable-table {
    border: none;
}
