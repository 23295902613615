@import './Constants.scss';

@import '../node_modules/bootstrap/dist/css/bootstrap.min.css' layer(bootstrap);

/* App.css */
@import '~@fortawesome/fontawesome-free/css/all.min.css';


@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

.asterisk-mark::after {
    content: '*';
    color: red;
}


.panel-no-padding .p-panel-content {
    padding: 0px;

}

.p-fileupload-content {
    display: none !important;
}

.p-fileupload-buttonbar {
    border-bottom: 1px solid #dee2e6;
}


.p-inputgroup-button {
    background-color: #E9ECEF;
    border-color: #CED4DA;
    border-left: none;
    color: #6C757D;
}

.p-filter-match-mode-dropdown {
    background-color: #E9ECEF;
    border-color: #CED4DA;
    border-left: none;
    padding-left: 2px;
    max-width: 37.71px !important;
}

.p-filter-match-mode-dropdown .p-dropdown-trigger {
    max-width: 0px;
}

.page-container-wide {
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;
}

.page-container-narrow {
    padding-top: 30px;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 30px;
}

.one-health-panel {
    border: 1px solid $app-border-color;
    padding: 0;
}

.one-health-panel-header {
    border-bottom: 1px solid $app-border-color;
    height: 49px;
    display: flex;
    padding: 3px;
    align-items: center;
}

html, body {
    height: 100%;
    margin: 0;
  }

  
  body {
    display: flex;
    flex-direction: column;
  }