@import '../Constants.scss';

.content {
    background-color: white;
    padding-top: $app-header-height;
    padding-bottom: 80px;
    max-width: 1920px;
    flex: 1;
}


.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex-wrap: 1;
}