@use '../Constants.scss' as c;


.app-footer {
    border: solid 1px c.$app-border-color;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    padding: 10px;
    margin: 0;
}